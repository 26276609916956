.Membership {
    text-align: center;
}

.ParkListDropdown {
    margin-top: 25px;
    border-radius: 30px;
    height: 58px;
    border: 1px solid var(--tg-gray-6);
    color: var(--tg-secondary-text);
}


.ParkListDropdown::part(container) {
    width: 100%;
    text-align: left;
    padding-left: 30px;
    padding-right: 30px;
}


.ParkListDropdown.active {
    background-color: var(--tg-dark);
    color: var(--tg-white);
}

.ParkListDropdown.active::part(container) {
    font-weight: 600;
}

.ParkListDropdown::part(icon) {
    color: var(--tg-dark);
}

.ParkListDropdown.active::part(icon) {
    color: white;
}

.parkDetailSummaryContainer {
    margin-top: 10px;
    width: 100%;
    height: 112px;
    border: 2px dashed #166DF7;
    background-color: #166DF71A;
    border-radius: 12px;
    padding: 10px 10px 3px 20px;
}

.ParkDetailContainer {
    height: calc(95% - 145px);
}

.ParkDetailTabsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 0 3px 0;
}

.ParkDetailTabsContainer p {
    color: var(--tg-secondary-text);
    font-weight: 600;
    text-align: center;
    width: 100%;
    border-bottom: 2px solid var(--tg-gray-5);
    padding-bottom: 10px;
}


.ParkDetailTabsContainer p.active {
    color: var(--tg-item-dark);
    border-bottom: 2px solid var(--tg-charge);
}

.parkDetailSummaryContainer .title {
    line-height: 1px;
    color: var(--tg-dark);
    font-size: 16px;
    font-weight: 600;
}

.parkDetailSummaryContainer .info {
    line-height: 1px;
    color: #006FFF;
    font-weight: 500;
    font-size: 12px;
}


.parkDetailSummaryContainer .summary {
    color: var(--tg-secondary-text);
    font-weight: 500;
    font-size: 14px;
}

.parkDetailSummaryContainer .summary .seperator {
    color: var(--tg-secondary-text);
    border-right: 1px solid gray;
    font-weight: 400;
    margin-left: 18px;
    margin-right: 18px;
}


.parkDetailSummaryContainer .summary span {
    color: var(--tg-dark);
    font-weight: 600;
}

.NoItemContainer {
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.NoItemContainer p {
    text-align: center;
}


.MembershipBottomGreenButton {
    position: absolute;
    bottom: 5%;
    background-color: var(--tg-primary);
    border-radius: 25px;
    color: white;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    width: 93%;
    height: 50px;
    padding-top: 15px;
    margin: auto;
}

.addVehicleTabButtonContainer{
    display: flex;
    position: absolute;
    z-index: 2;
    bottom: 5%;
    width: 100%;
    justify-content: center;
    align-items: center;
    left: 0;
    gap: 10px;
}

.addVehicleTabButton {
    background-color: var(--tg-primary);
    border-radius: 25px;
    color: white;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.addVehicleTabButton.no-bg {
    background-color: white;
    border: 1px solid var(--tg-charge);
    color: var(--tg-dark);
    display: flex;
    width: 125%;
}

.MembershipBottomGreenButton.disabled {
    background-color: var(--tg-gray-2);
}

.NoItemContainer p:first-of-type {
    margin-top: 25px;
    font-size: 16px;
    font-weight: 600;
    color: #191E26;
}

.NoItemContainer p:last-of-type {
    margin-top: -3px;
    font-size: 14px;
    color: #666666;
}


.ParkDetailTabSubscriptions {
    overflow-y: auto;
    max-height: calc(95% - 325px);
    margin-top: 25px;
}

.SubscriptionSlideItem:not(:first-child) {
    margin-top: 10px;
}

.SubscriptionSlideItem .vehicleItemBackground {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #EC4E2C;
    border-radius: 20px;
    z-index: -1;
}

.SubscriptionSlideItem .MembershipVehicleOptions {
    border-bottom-width: 0;
}

.SubscriptionSlideItem .MembershipVehicleOption {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #ec4e2c;
}

.SubscriptionSlideItem .actionButtonSlide {
    width: 67px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.ParkDetailTabSubscriptions .BottomBtn {
    position: absolute;
    bottom: 5%;
    width: calc(100% - 32px);
}

.ParkDetailTabSubscriptions .BottomBtn .TotalPrice {
    text-align: center;
    margin-bottom: 20px;
    color:var(--tg-solid-gray);
}

.ParkDetailTabSubscriptions .BottomBtn span {
    font-size: 14px;
    color: var(--tg-solid-gray);
}

.ParkDetailTabSubscriptions .BottomBtn .TotalPrice b {
    font-size: 20px;
    font-weight: bold;
    color:#333333;
    margin-left: 6px;
}

.ParkDetailTabSubscriptions .BottomBtn .SubscriptionInfo {
    display: grid;
    justify-content: center;
    grid-template-columns: 40% 5px 47%;
    gap: 5px;
    color:#191E26CC;
}

.ParkDetailTabSubscriptions .BottomBtn .SubscriptionInfo:last-child {
    padding-bottom: 20px;
    border-bottom: 1px solid var(--tg-gray-6);
    margin-bottom: 20px;
}


.SubscriptionItem {
    border: 2px dashed var(--tg-gray-6);
    height: 96px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 17px;
}

.ParkDetailTabSubscriptions .CheckboxWrapper {
    padding: 10px 12px;
}

.VehicleItem {
    border : 1px solid var(--tg-gray-6);
    height: 96px;
    border-radius: 12px;
}

.VehicleItem .VehicleImage {
    object-position: 0;
    margin-left: -25px;
}

.SubscriptionItem div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.SubscriptionItem.selected {
    border-color: var(--tg-primary);
    background-color: var(--tg-dust-green-2);
}

.SubscriptionItem:not(:first-child){
    margin-top: 12px;
}

.SubscriptionItem .Badge, .VehicleItem .Badge {
    width: 57px;
    height: 16px;
    font-size: 10px;
    line-height: 16px;
    font-weight: 500;
    color: white;
    text-align: center;
    border-radius: 3px 12px 3px 3px;
}

.SubscriptionItem .Badge.free, .VehicleItem .Badge.free {
    background-color: var(--tg-charge);
}

.SubscriptionItem .Badge.subscription, .VehicleItem .Badge.subscription {
    background-color: var(--tg-park);
}
.VehicleItem div {
    flex-shrink: 0;
}

.VehicleItem .vehicleInfo {
    margin-left: auto;
    height: 100%;
    padding-top: 16px;
    padding-right: 16px;
}

.VehicleItem .vehicleInfo p {
    font-size: 9px;
    max-width: 125px;
    text-align: end;
    margin-bottom: auto;
    color: var(--tg-secondary-text);
}

.VehicleItem .vehicleInfo .Badge {
    margin-left: auto;
}

.SubscriptionItem .SubscriptionInfo {
    line-height: 10px;
    font-size: 16px;
    font-weight: 600;
    margin-right: 12px;
}

.VehicleItem .vehiclePlate {
    color: var(--tg-secondary-text);
    font-size: 14px;
    font-weight: 400;
    margin-top: -10px
}

.VehicleItem .vehiclePlate.noModel {
    color: var(--tg-dark);
    font-size: 16px;
    font-weight: 600;
}


.VehicleItem .vehicleModel {
    font-size: 14px;
    font-weight: 600;
    max-width: 98%;
}

.SubscriptionItem.selected {
    border-color: var(--tg-charge);
    background-color: var(--tg-dust-green-2);
    border-style: dashed;
    border-width: 2px;

}

.SubscriptionItem .IconContainer {
    position: absolute;
    bottom: 20px;
    right: 20px;
    padding: 3px 5px 5px 5px;
    background-color: var(--tg-charge);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.ParkDetailTabSubscriptions .AddVehicleWhiteButton {
    display: flex;
    font-size: 13px;
    font-weight: 500;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 8px;
    margin-top: 16px;
    border-radius: 18px;
    background-color: #FFFFFF;
    box-shadow: 0 0.5pt 2pt rgba(0, 0, 0, 0.1);
    height: 35px;
    width: 120px;
}

.ParkDetailTabSubscriptions .centeredContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.MembershipVehicles {
    overflow-y: auto;
    height: calc(95% - 165px);
}

.deleteMembershipModal, .addVehicleModal {
    text-align: center;
}

.addVehicleModal p {
    margin-top: 0;
    margin-bottom: 10px;
}

.addVehicleModal .enterPlateWrapper {
    width: 100%;
    padding: 0 10px;
}

.deleteMembershipModal p:first-of-type, .addVehicleModal p:first-of-type {
    font-size: 18px;
    color: #000000;
    font-weight: 600;
    margin-top: 0;
}

.deleteMembershipModal p:last-of-type {
    font-size: 14px;
    color: #939598;
    max-width: 80%;
    margin: 0 auto;
}


.deleteMembershipModal p:last-of-type b {
    color: #000000;
    font-weight: 600;
}

.addVehicleModal p:last-of-type {
    font-size: 14px;
    color: #000000;
    font-weight: 400;
    max-width: 300px;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
}

.deleteMembershipModal .deleteButton, .addVehicleModal .addButton {
    margin-top: 30px;
    border-radius: 25px;
    background-color: #EC4E2C;
    color: white;
    height: 50px;
    width: calc(100% - 20px);
    padding: 0 20px;
    font-size: 16px;
    font-weight: 500;
}

.addVehicleModal .addButton {
    background-color: var(--tg-charge);
}

.addVehicleModal .vehicleImgLabel {
    position: absolute;
    top: 66px;
    left: 50%;
    transform: translateX(-50%);
    margin-left: 6px;
    font-weight: 700;
    font-size: 22px;
    width: 100%;
    max-width: 125px;
    text-align: center;
}

.addVehicleModal .vehicleInput {
    background-color: #E9EAEA;
    border-radius: 29px;
    border: 1px solid #E9EAEA;
    padding: 28px;
    font-size: 18px;
    color: black;
    font-weight: 500;
    height: 58px;
    width: 100%;
}

@media only screen and (max-width: 375px) {
    .addVehicleModal .addButton {
        margin-top: 30px;
    }

    .vehicleInputLabel {
        margin-top: 10px;
    }

    .addVehicleTabButton.no-bg{
        width: 165%;
    }
}

.AddSubscription .enterPlateWrapper .vehicleInput {
    background-color: #E9EAEA;
    border-radius: 29px;
    border: 1px solid #E9EAEA;
    padding: 28px;
    font-size: 18px;
    color: black;
    font-weight: 500;
    height: 58px;
    width: 100%;
}

