.StationDetail .SocketTypes {
    transform: translateY(0);
    animation: StationDetailElements .4s ease-in;
}

.StationDetail .SocketTypesList {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.StationDetail .SocketTypeItem{
    flex: 1;
    height: 45px;
    border-radius: 10px;
    border: 1px solid var(--tg-inactive);
    background-color: var(--tg-transparent);
    margin-bottom: 15px;
    padding: 6px;
    display: flex;
    align-items: center;
    gap: 7px;
}
.StationDetail .SocketTypeItem:nth-child(odd):not(:last-child){
    margin-right: 10px;
}
.StationDetail .SocketTypeItemInfo{
    display: flex;
    flex-direction: column;
    flex: 1;
}
.StationDetail .SocketTypeItemInfoSecondLine{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.StationDetail .SocketTypeAvailability{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;
}
.StationDetail .ShowAllSocketsButton{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.StationDetail .Prices{
    border: 1px solid var(--tg-inactive);
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 10px;
    animation: StationDetailElements .4s ease-in;
}

.StationDetail .Price {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.StationDetail .Price>div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
}

.StationDetail .Price>div:nth-child(2) {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
}

.StationDetail .Address{
    border: 1px solid var(--tg-inactive);
    padding: 10px;
    border-radius: 10px;
    margin-top: 15px;   
    display: flex;
    flex-direction: column;
    gap: 10px;
    animation: StationDetailElements .4s ease-in;
}

.StationDetail .Address > div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


.StationDetail .AddressIconWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background-color: var(--tg-purple);
    width: 24px;
    height: 24px;
}

.StationDetail .PhoneIconWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background-color: var(--tg-secondary-text);
    width: 24px;
    height: 24px;
}

.StationDetail .FastCharge{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    border-radius: 10px;
    padding: 10px;
    background-color: var(--tg-orange10);
    border: 0.5px solid var(--tg-orange);
    margin: 15px 0;
    animation: StationDetailElements .4s ease-in;
}

.DirectionButton .DistanceLabel{
    background-color: var(--tg-primary-text);
    border-radius: 4px;
}

.ActionButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 9px;
    position: fixed;
    width: calc(100% - 42px);
}

.ComingSoon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

.ComingSoon .alert-wrapper {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--tg-orange10);
    border: 1px solid var(--tg-orange);
    border-radius: 10px;
    padding: 8px 20px;
    font-size: 14px;
    text-align: center;
}
.ComingSoon .alert-wrapper span {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 2px;
}