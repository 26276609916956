
.TicketList {
    display: flex;
    flex-direction: column;
    height: 100%;

}
.TicketItem {
    border: 1px solid var(--tg-gray);
    padding: 1rem;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}

.TicketItem.bg-active {
    background: var(--tg-gray-6);
}

.TicketItem:not(:last-child) {
    margin-bottom: 1rem;
}

.TicketInner{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    padding-right: 8px;
}

.TicketShortInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.TicketSecondLine {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.TicketStatus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
}
.TicketStatus span {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    display: inline-block;
}

.TicketDetailTop {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.TicketThread {
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 1rem;
}

.TicketThread.supervisor {
    background: 1px solid var(--tg-gray);
}

.TicketThread.supervisor p p {
    margin: 0;
}

.TicketThread.customer {
    background: var(--tg-gray);
}

.ThreadTop {
    padding-bottom: 1rem;
    display: flex;
    justify-content: space-between;
}

.TicketForm {
    height: calc(100% - 2rem);
    display: flex;
    flex-direction: column;
}

.TicketForm > * {
    flex-shrink: 0;
}